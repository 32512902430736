<template>
  <v-content>
    <v-container>
      <v-form ref="form" class="mb-10">
        <v-row align="center" justify="center">
          <v-col :cols="12" :sm="12">
            <span class="text-left gray--text fontheader">Store Information</span>
          </v-col>
          <v-col :cols="6" :sm="6" class="py-0">
            <v-text-field
              v-model="name"
              prepend-inner-icon="perm_identity"
              placeholder="ชื่อ"
              :rules="nameRules"
              solo
            />
          </v-col>
          <v-col :cols="6" :sm="6" class="py-0">
            <v-text-field
              prepend-inner-icon="perm_identity"
              placeholder="นามสกุล"
              required
              v-model="lastname"
              :rules="lastnameRules"
              solo
            />
          </v-col>
          <v-col :cols="12" :sm="12" class="py-0">
            <v-text-field
              prepend-inner-icon="language"
              placeholder="ชื่อสถานประกอบการ"
              required
              v-model="shopName"
              :rules="shopNameRules"
              solo
            />
          </v-col>
          <v-col :cols="12" :sm="12" class="py-0">
            <v-text-field
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              prepend-inner-icon="phone"
              placeholder="เบอร์โทร"
              type="number"
              maxlength="10"
              :rules="telRules"
              required
              v-model="tel"
              solo
            />
          </v-col>
          <v-col :cols="12" :sm="12" class="py-0">
            <v-text-field
              prepend-inner-icon="perm_identity"
              placeholder="อีเมล"
              v-model="email"
              type="email"
              :rules="emailRules"
              required
              solo
            ></v-text-field>
          </v-col>
          <v-col :cols="12" :sm="12">
            <span class="text-left gray--text fontheader">Store Location</span>
          </v-col>
          <v-col :cols="12" :sm="12" class="py-0">
            <v-text-field
              solo
              v-model="address"
              placeholder="ที่อยู่"
              :rules="addressRules"
              required
            />
          </v-col>
          <v-col :cols="6" :sm="6" class="py-0">
            <ThailandAuto
              v-model="subDistrict"
              type="district"
              @select="select"
              placeholder="ตำบล"
              :rules="subDistrictRules"
              required
            />
          </v-col>
          <v-col :cols="6" :sm="6" class="py-0">
            <ThailandAuto
              v-model="district"
              type="amphoe"
              @select="select"
              placeholder="อำเภอ"
              :rules="districtRules"
              required
            />
          </v-col>
          <v-col :cols="6" :sm="6" class="py-0">
            <ThailandAuto
              v-model="province"
              type="province"
              @select="select"
              placeholder="จังหวัด"
              :rules="provinceRules"
              required
            />
          </v-col>
          <v-col :cols="6" :sm="6" class="py-0">
            <ThailandAuto
              v-model="zipcode"
              type="zipcode"
              @select="select"
              placeholder="รหัสไปรษณีย์"
              :rules="zipcodeRules"
              required
            />
          </v-col>
        </v-row>
        <Button title="ยืนยัน" :func="createShop"></Button>
      </v-form>
    </v-container>
  </v-content>
</template>

<script>
import ThailandAuto from '../components/ThailandAuto'
import Button from '../components/Button'
export default {
  name: 'FormShop',
  components: {
    ThailandAuto,
    Button,
  },
  data() {
    return {
      valid: false,
      name: '',
      nameRules: [v => !!v || 'ป้อนชื่อ'],
      lastname: '',
      lastnameRules: [v => !!v || 'ป้อนนามสกุล'],
      tel: '',
      telRules: [v => !!v || 'ป้อนเบอร์โทรศัพท์'],
      email: '',
      emailRules: [v => !!v || 'ป้อนอีเมล', v => /.+@.+\..+/.test(v) || 'อีเมลไม่ถูกต้อง'],
      shopName: '',
      shopNameRules: [v => !!v || 'ป้อนชื่อสถานประกอบการ'],
      address: '',
      addressRules: [v => !!v || 'ป้อนที่อยู่'],
      subDistrict: '',
      subDistrictRules: [v => !!v || 'ป้อนตำบล'],
      district: '',
      districtRules: [v => !!v || 'ป้อนอำเภอ'],
      province: '',
      provinceRules: [v => !!v || 'ป้อนจังหวัด'],
      zipcode: '',
      zipcodeRules: [v => !!v || 'ป้อนรหัสไปรษณีย์'],
    }
  },
  props: {
    addressProps: {
      type: Object,
      // default() {
      //   return {}
      // },
    },
    storeName: {
      type: String,
      default: '',
    },
  },
  methods: {
    async createShop() {
      const self = this
      try {
        const validateResult = self.$refs.form.validate()
        if (validateResult) {
          self.$loading(true)
          const res = await self.$axios({
            method: 'POST',
            url: '/api/store/update',
            data: {
              phone: self.tel,
              manager_name: self.name,
              manager_lastname: self.lastname,
              store_email: self.email,
              name: self.shopName,
              social_id: self.$store.state.tokenId,
              address_1: self.address,
              subdistrict: self.subDistrict,
              district: self.district,
              postal_code: self.zipcode,
              province: self.province,
              store_id: self.$route.query.shopid,
            },
          })
          if (res.data.status === 'success') {
            self.$modal.show({
              title: res.data.message,
              isOne: true,
              onConfirm: () => self.$router.push('/listshop'),
              onClose: () => self.$router.push('/listshop'),
            })
          } else {
            self.$modal.show({
              title: `ERROR: ${res.data.message}`,
              isOne: true,
            })
          }
        }
      } catch (e) {
        self.$modal.show({
          title: `ERROR: ${e}`,
          isOne: true,
        })
      } finally {
        self.$loading(false)
      }
    },
    select(address) {
      this.subDistrict = address.district
      this.district = address.amphoe
      this.province = address.province
      this.zipcode = address.zipcode
    },
    async getShopData() {
      try {
        const self = this
        if (self.$route.query.shopid) {
          const res = await self.$axios({
            method: 'GET',
            url: '/api/store/view',
            params: {
              social_id: self.$store.state.tokenId,
              store_id: self.$route.query.shopid,
            },
          })
          if (res.data.status === 'failed') {
            self.$modal.show({
              title: `ERROR: ${res.data.message}`,
              isOne: true,
            })
          }
          const splitName = res.data[0].store_manager_name
            ? res.data[0].store_manager_name.split(' ')
            : ['', '']
          const firstName = splitName[0]
          const lastName = splitName[1]
          self.name = firstName
          self.lastname = lastName
          self.shopName = res.data[0].name_store
          self.tel = res.data[0].store_phone_1
          self.email = res.data[0].store_email
          self.address = res.data[0].address.address_1
          self.subDistrict = res.data[0].address.subdistrict
          self.district = res.data[0].address.district
          self.zipcode = res.data[0].address.postal_code
          self.province = res.data[0].address.province
        }
      } catch (e) {
        this.$modal.show({
          title: `ERROR: ${e}`,
          isOne: true,
        })
      }
    },
  },
  mounted() {
    this.getShopData()
  },
}
</script>

<style lang="scss" scoped></style>
